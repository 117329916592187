import React, { useEffect, useState } from 'react';

const ScoresTable = () => {
  const [scores, setScores] = useState([]);

  const [leadership,setleadership] = useState([]);
  const [integrity,setIntegrity] = useState([]);
  const [excellence,setExcellence] = useState([]);
  const [partnership,setPartnership] = useState([]);
  const [users, setusers] = useState();
  console.log(users);


  useEffect(() => {
    // Fetch data from your API
    const fetchData = async () => {
      const response = await fetch('https://employeebackedsm2024.onrender.com/api/professionalism/get-scores');
      const data = await response.json();
      setScores(data.scores);
    };

    fetchData();
  }, []);


  useEffect(() => {
    // Fetch data from your API
    const fetchIntegrityData = async () => {
      const response = await fetch('https://employeebackedsm2024.onrender.com/api/integerity/get-scores');
      const data = await response.json();
      setIntegrity(data.scores);
    };

    fetchIntegrityData();
  }, []);

  // Function to aggregate scores by name
  const aggregateScores = (scores) => {
    const aggregated = {};

    scores.forEach((score) => {
      // If the name already exists, add to the totalScore
      if (aggregated[score.name]) {
        aggregated[score.name].totalScore += score.totalScore;
      } else {
        // Otherwise, create a new entry
        aggregated[score.name] = {
          name: score.name,
          totalScore: score.totalScore,
          voterId: score.voterId,  // Keeping the first voterId associated with that name
        };
      }
    });

    // Convert the aggregated object back to an array
    return Object.values(aggregated);
  };

  // Aggregate scores based on name
  const aggregatedScores = aggregateScores(scores);


  const aggregateintegrityscores = (integrity) => {
    const aggregated = {};

    integrity.forEach((score) => {
      // If the name already exists, add to the totalScore
      if (aggregated[score.name]) {
        aggregated[score.name].totalScore += score.totalScore;
      } else {
        // Otherwise, create a new entry
        aggregated[score.name] = {
          name: score.name,
          totalScore: score.totalScore,
          voterId: score.voterId,  // Keeping the first voterId associated with that name
        };
      }
    });

    // Convert the aggregated object back to an array
    return Object.values(aggregated);
  };

  // Aggregate scores based on name
  const integerityAggregate = aggregateintegrityscores(integrity);



  useEffect(() => {
    // Fetch data from your API
    const fetchPartnership = async () => {
      const response = await fetch('https://employeebackedsm2024.onrender.com/api/pertnership/get-scores');
      const data = await response.json();
      setPartnership(data.scores);
    };

    fetchPartnership();
  }, []);



  const aggregatePartnership = (partnership) => {
    const aggregated = {};

    partnership.forEach((score) => {
      // If the name already exists, add to the totalScore
      if (aggregated[score.name]) {
        aggregated[score.name].totalScore += score.totalScore;
      } else {
        // Otherwise, create a new entry
        aggregated[score.name] = {
          name: score.name,
          totalScore: score.totalScore,
          voterId: score.voterId,  // Keeping the first voterId associated with that name
        };
      }
    });

    // Convert the aggregated object back to an array
    return Object.values(aggregated);
  };

  // Aggregate scores based on name
  const partnershipAggregate = aggregatePartnership(partnership);


  useEffect(() => {
    // Fetch data from your API
    const fetchleadership = async () => {
      const response = await fetch('https://employeebackedsm2024.onrender.com/api/scores/get-scores');
      const data = await response.json();
      setleadership(data.scores);
    };

    fetchleadership();
  }, []);


  const aggregateLeadership = (leadership) => {
    const aggregated = {};

    leadership.forEach((score) => {
      // If the name already exists, add to the totalScore
      if (aggregated[score.name]) {
        aggregated[score.name].totalScore += score.totalScore;
      } else {
        // Otherwise, create a new entry
        aggregated[score.name] = {
          name: score.name,
          totalScore: score.totalScore,
          voterId: score.voterId,  // Keeping the first voterId associated with that name
        };
      }
    });

    // Convert the aggregated object back to an array
    return Object.values(aggregated);
  };

  // Aggregate scores based on name
  const aggregateLeaderahip = aggregateLeadership(leadership);






  useEffect(() => {
    // Fetch data from your API
    const fetchExcellence = async () => {
      const response = await fetch('https://employeebackedsm2024.onrender.com/api/excellence/get-scores');
      const data = await response.json();
      setExcellence(data.scores);
    };

    fetchExcellence();
  }, []);


  const aggregateExcellence = (excellence) => {
    const aggregated = {};

    excellence.forEach((score) => {
      // If the name already exists, add to the totalScore
      if (aggregated[score.name]) {
        aggregated[score.name].totalScore += score.totalScore;
      } else {
        // Otherwise, create a new entry
        aggregated[score.name] = {
          name: score.name,
          totalScore: score.totalScore,
          voterId: score.voterId,  // Keeping the first voterId associated with that name
        };
      }
    });

    // Convert the aggregated object back to an array
    return Object.values(aggregated);
  };

  // Aggregate scores based on name
  const  aggregatedExcellence = aggregateExcellence(excellence);
 


  

  useEffect(() => {
    // Fetch data from your API
    const fetchUsers = async () => {
    const response = await fetch('https://employeebackedsm2024.onrender.com/api/pertnership/get-scores');
      const data = await response.json();
      setusers(data.scores);
    };

    fetchUsers();
  }, []);
  return (
   
    <>

    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-semibold text-center mb-4">Scores in Leadership</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 text-left">Voter Name</th>
              <th className="py-2 px-4 text-left">Total Score</th>
              <th className="py-2 px-4 text-left">Number of Employess who selected above 0</th>
            </tr>
          </thead>
          <tbody>
            {aggregateLeaderahip.map((score) =>(
              <tr key={score.name} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4">{score.name}</td>
                <td className="py-2 px-4">{score.totalScore}</td>
                <td className="py-2 px-4">{((score.totalScore)/24).toFixed(0)}</td>
            
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-semibold text-center mb-4">Scores in Integrity</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 text-left">Voter Name</th>
              <th className="py-2 px-4 text-left">Total Score</th>
              <th className="py-2 px-4 text-left">Number of Employess who selected above 0</th>
            </tr>
          </thead>
          <tbody>
            {integerityAggregate.map((score) => (
              <tr key={score.name} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4">{score.name}</td>
                <td className="py-2 px-4">{score.totalScore}</td>
                <td className="py-2 px-4">{((score.totalScore)/24).toFixed(0)}</td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

    
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-semibold text-center mb-4">Scores in Partnership</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 text-left">Voter Name</th>
              <th className="py-2 px-4 text-left">Total Score</th>
              <th className="py-2 px-4 text-left">Number of Employess who selected above 0</th>
            
            </tr>
          </thead>
          <tbody>
            {partnershipAggregate.map((score) => (
              <tr key={score.name} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4">{score.name}</td>
                <td className="py-2 px-4">{score.totalScore}</td>
                <td className="py-2 px-4">{((score.totalScore)/24).toFixed(0)}</td>
                
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-semibold text-center mb-4">Scores in Professionalism</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 text-left">Voter Name</th>
              <th className="py-2 px-4 text-left">Total Score</th>
              <th className="py-2 px-4 text-left">Number of Employess who selected above 0</th>
            
            </tr>
          </thead>
          <tbody>
            {aggregatedScores.map((score) => (
              <tr key={score.name} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4">{score.name}</td>
                <td className="py-2 px-4">{score.totalScore}</td>
                <td className="py-2 px-4">{((score.totalScore)/24).toFixed(0)}</td>
                
              
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>





    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-semibold text-center mb-4">Scores in Excellence</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 text-left">Voter Name</th>
              <th className="py-2 px-4 text-left">Total Score</th>
              <th className="py-2 px-4 text-left">Number of Employess who selected above 0</th>
              
              

            
            </tr>
          </thead>
          <tbody>
            {aggregatedExcellence.map((score) => (
              <tr key={score.name} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4">{score.name}</td>
                <td className="py-2 px-4">{score.totalScore}</td>
                <td className="py-2 px-4">{((score.totalScore)/24).toFixed(0)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
};

export default ScoresTable;
