import React, { useEffect, useState } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router";

function Excellence() {
  const  allEmployees = [
    "Abudlah Chambaga",
    "Andrew Arthur Agaba",
    "Brendah Kababwiju",
    "Christine Birungi",
    "Goretti Masadde",
    "Ibrahim Gava Kalule",
    "John Bosco Habere",
    "John Walusimbi",
    "Jude Mufuumula",
    "Kelvin Abaasa",
    "Lawrence Tebandeke",
    "Mary Florence Kayaga",
    "Mary Kansiime",
    "Mudasiru Tamuzadde",
    "Pamela Nakintu",
    "Peter Kabaseke Musinguzi",
    "Priscilla Mwesige",
    "Priscillar Tumusiime",
    "Richard Semakula",
    "Ronald Mugwanya",
    "Sam Damba",
    "Warren Mwesigye",
  ];
  const [scores, setScores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decoded = jwt_decode(token);
        const userName = decoded.name;
        const filteredEmployees = allEmployees.filter(
          (employee) => employee !== userName
        );
        setScores(
          filteredEmployees.map((name) => ({
            name,
            attributes: Array(5).fill(0), // 5 attributes, initialized to 0
          }))
        );
      } catch (error) {
        console.error("Error decoding token:", error);
        toast.error("Invalid token. Please log in again.");
      }
    }
  }, []);

  const handleChange = (index, attributeIndex, value) => {
    const parsedValue = parseInt(value) || 0;
    setScores((prevScores) => {
      const updatedScores = [...prevScores];
      updatedScores[index].attributes[attributeIndex] = parsedValue;
      return updatedScores;
    });
  };

  const calculateTotal = (attributes) => attributes.reduce((a, b) => a + b, 0);

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("You must be logged in to submit scores.");
      return;
    }

    // const incompleteForm = scores.some(({ attributes }) => attributes.includes(0));
    // if (incompleteForm) {
    //   toast.error("Please fill out all the attributes before submitting.");
    //   return;
    // }

    const dataToSend = scores.map(({ name, attributes }) => ({
      name,
      total: calculateTotal(attributes),
    }));

    setIsLoading(true);

    try {
      await axios.post(
        "https://employeebackedsm2024.onrender.com/api/integerity/submit-scores",
        { data: dataToSend },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );


      toast.success("Scores submitted successfully!");
     
      navigate('/professionalism');
    } catch (error) {
      if (error.response) {
        console.error("Server error:", error.response.data);
        toast.error(error.response.data.error);
      } else {
        console.error("Network error:", error.message);
        toast.error("An error occurred. Please check your network and try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderDropdown = (index, attributeIndex) => (
    <select
      defaultValue=""
      className="border border-gray-300 rounded px-2 py-1 focus:ring-blue-500 focus:border-blue-500"
      onChange={(e) => handleChange(index, attributeIndex, e.target.value)}
    >
      <option value="" disabled>Select...</option>
      {[0,1, 2, 3, 4, 5,'abn'].map((value) => (
        <option key={value} value={value}>{value}</option>
      ))}
    </select>
  );

  return (
    <>
      <Navbar />
      <ToastContainer />
      <div className="container mx-auto py-6 px-4">
        <h1 className="text-2xl font-bold mb-4 text-center">Score each employee in Integrity</h1>
        <h3 className="text-sm font-bold mb-4 text-center">How has the employee demonstrated honesty and transparency, consistency in actions and words, ethical decision-making, accountability, and respect for confidentiality, fostering trust and upholding the organization’s values?</h3>
        <div className="overflow-x-auto">
          <table className="table-auto w-full border border-gray-200 shadow-md">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 text-left font-medium text-gray-600">#</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Employee Name</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Honesty and transparency</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Ethical decision making</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Consistency in Actions and words</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Accountability</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Respect for confidentiality</th>
              </tr>
            </thead>
            <tbody>
              {scores.map(({ name, attributes }, index) => (
                <tr key={index} className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"} hover:bg-blue-50`}>
                  <td className="px-4 py-2 border">{index + 1}</td>
                  <td className="px-4 py-2 border">{name}</td>
                  {attributes.map((_, attributeIndex) => (
                    <td key={attributeIndex} className="px-4 py-2 border text-center">
                      {renderDropdown(index, attributeIndex)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 text-center">
            <button
              onClick={handleSubmit}
              disabled={isLoading}
              className={`${
                isLoading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"
              } text-white px-4 py-2 rounded`}
            >
              {isLoading ? "Submitting..." : "Submit Scores"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Excellence;
