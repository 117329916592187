import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router';
import * as XLSX from 'xlsx'; // Import xlsx library

function Professionalism() {
  const navigate = useNavigate();
  const employees = [
    "Abudlah Chambaga",
    "Andrew Arthur Agaba",
    "Brendah Kababwiju",
    "Christine Birungi",
    "Goretti Masadde",
    "Ibrahim Gava Kalule",
    "John Bosco Habere",
    "John Walusimbi",
    "Jude Mufuumula",
    "Kelvin Abaasa",
    "Lawrence Tebandeke",
    "Mary Florence Kayaga",
    "Mary Kansiime",
    "Mudasiru Tamuzadde",
    "Pamela Nakintu",
    "Peter Kabaseke Musinguzi",
    "Priscilla Mwesige",
    "Priscillar Tumusiime",
    "Richard Semakula",
    "Ronald Mugwanya",
    "Sam Damba",
    "Warren Mwesigye",
  ];

  const [scores, setScores] = useState(
    employees.map((name) => ({
      name,
      attributes: Array(5).fill(0), // 5 attributes, initialized to 0
    }))
  );
  const [filteredScores, setFilteredScores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState('');

  // Decode token and filter out the logged-in user's name
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userName = decodedToken.name; 
        setLoggedInUser(userName);

        const updatedScores = scores.filter((score) => score.name !== userName);
        setFilteredScores(updatedScores);
      } catch (error) {
        toast.error('Invalid token. Please log in again.');
      }
    }
  }, [scores]);

  const handleChange = (index, attributeIndex, value) => {
    setFilteredScores((prevScores) => {
      const updatedScores = [...prevScores];
      updatedScores[index].attributes[attributeIndex] = parseInt(value) || 0;
      return updatedScores;
    });
  };

  const calculateTotal = (attributes) => attributes.reduce((a, b) => a + b, 0);

  // Generate Excel file with filled data and totals
  const generateExcelFile = (data) => {
    const worksheetData = [
      ['Employee Name', 'Adherence to standards', 'Responsibility and reliability', 'Respectful communication', 'Presentation and conduct', 'Time management and organization', 'Total']
    ];

    data.forEach(({ name, attributes }) => {
      const total = calculateTotal(attributes);
      worksheetData.push([name, ...attributes, total]);
    });

    const ws = XLSX.utils.aoa_to_sheet(worksheetData); // Convert array of arrays to worksheet
    const wb = XLSX.utils.book_new(); // Create new workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Scores'); // Append worksheet to workbook

    // Write the Excel file to the browser
    XLSX.writeFile(wb, 'employee_scores.xlsx');
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('You must be logged in to submit scores.');
      return;
    }

    // Check if there is at least one nomination
    const hasNominated = filteredScores.some(({ attributes }) =>
      attributes.some((value) => value > 0)
    );

    if (!hasNominated) {
      toast.error('Please nominate at least one employee before submitting.');
      return;
    }

    const dataToSend = filteredScores.map(({ name, attributes }) => ({
      name,
      total: calculateTotal(attributes),
    }));

    setIsLoading(true);

    try {
      const response = await axios.post(
        'http://localhost:5000/api/professionalism/submit-scores',
        { data: dataToSend },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      toast.success('Scores submitted successfully!');
      
      // Generate and download the Excel file
      generateExcelFile(filteredScores);

      navigate('/partnership');
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error);
      } else {
        toast.error('An error occurred. Please check your network and try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderDropdown = (index, attributeIndex) => (
    <select
      defaultValue=""
      className="border border-gray-300 rounded px-2 py-1 focus:ring-blue-500 focus:border-blue-500"
      onChange={(e) => handleChange(index, attributeIndex, e.target.value)}
    >
      <option value="" disabled>Select...</option>
      {[0,1, 2, 3, 4, 5].map((value) => (
        <option key={value} value={value}>{value}</option>
      ))}
    </select>
  );

  return (
    <>
      <Navbar />
      <ToastContainer />
      {/* <div className="container mx-auto py-6 px-4">
        <h1 className="text-2xl font-bold mb-4 text-center">Score each employee in Professionalism</h1>

        <h3 className="text-sm font-bold mb-4 text-center">
          How has the employee demonstrated visionary thinking, inspired and motivated others, made effective decisions, fostered collaboration and team building, and exhibited ownership and accountability in their leadership role, contributing to exceptional team and organizational success?
        </h3>
        
        <div className="overflow-x-auto">
          <table className="table-auto w-full border border-gray-200 shadow-md">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 text-left font-medium text-gray-600">#</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Employee Name</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Adherence to standards</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Responsibility and reliability</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Respectful communication</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Presentation and conduct</th>
                <th className="px-4 py-2 text-left font-medium text-gray-600">Time management and organization</th>
              </tr>
            </thead>
            <tbody>
              {filteredScores.map(({ name, attributes }, index) => (
                <tr key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-blue-50`}>
                  <td className="px-4 py-2 border">{index + 1}</td>
                  <td className="px-4 py-2 border">{name}</td>
                  {attributes.map((_, attributeIndex) => (
                    <td key={attributeIndex} className="px-4 py-2 border text-center">
                      {renderDropdown(index, attributeIndex)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          
          <div className="mt-4 text-center">
            <button
              onClick={handleSubmit}
              disabled={isLoading}
              className={`${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'} text-white px-4 py-2 rounded`}
            >
              {isLoading ? 'Submitting...' : 'Submit Scores'}
            </button>
          </div>
        </div>
      </div> */}


      <div>
        <h1> System is offline</h1>
       
      </div>
    </>
  );
}

export default Professionalism;
